<template>
  <div>
    <ul class="ulStyle">
<!--      1-->
      <li v-if="currentNum()==='1'">
        <div class="item">
          <div class="top">
            <img src="./img/q2.svg" alt="">
          </div>
          <div class="title">画像属性</div>
        </div>
      </li>
      <li v-if="currentNum()==='1'">
        <div class="item">
          <div class="top">
            <img src="./img/q3.svg" alt="">
          </div>
          <div class="title">物料集合</div>
        </div>
      </li>
      <li v-if="currentNum()==='1'">
        <div class="item">
          <div class="top">
            <img src="./img/q4.svg" alt="">
          </div>
          <div class="title">场景服务</div>
        </div>
      </li>      <li v-if="currentNum()==='1'">
      <div class="item">
        <div class="top">
          <img src="./img/q.svg" alt="">
        </div>
        <div class="title">物料分群</div>
      </div>
    </li>
<!--3-->


      <li v-if="currentNum()==='3'">
        <div class="item">
          <div class="top">
            <img src="./img/q3.svg" alt="">
          </div>
          <div class="title">调参方式</div>
        </div>
      </li>
      <li v-if="currentNum()==='3'">
        <div class="item">
          <div class="top">
            <img src="./img/q4.svg" alt="">
          </div>
          <div class="title">保障系统</div>
        </div>
      </li>

<!--      4-->
      <li v-if="currentNum()==='4'">
        <div class="item">
          <div class="top">
            <img src="./img/q3.svg" alt="">
          </div>
          <div class="title">销售预测</div>
        </div>
      </li>
      <li v-if="currentNum()==='4'">
        <div class="item">
          <div class="top">
            <img src="./img/q4.svg" alt="">
          </div>
          <div class="title">库存周转率</div>
        </div>
      </li>
      <li v-if="currentNum()==='4'">
        <div class="item">
          <div class="top">
            <img src="./img/q2.svg" alt="">
          </div>
          <div class="title">缺货风险</div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import {currentNum} from "@/utils/data";

export default {
  name: "groupMangement",
  methods: {
    currentNum() {
      return currentNum
    }
  },

}
</script>


<style scoped>

</style>