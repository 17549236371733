<template>
  <div>
    <banner-top :topInfo="topInfo"></banner-top>
    <div class="main">
      <div class="introduce">
        <div class="title">企业介绍</div>
        <div class="en">ABOUT</div>
      </div>
      <div class="content">
<!--        <p>-->
<!--          <span class="title"> 中关村数智人工智能产业联盟</span>-->
<!--        </p>-->
        <p><span class="b">中关村数智人工智能产业联盟</span>（简称“ZAI”），是在北京市科学技术委员会、中关村科技园区管理委员会等单位的支持下，于2020年6月1日成立的，具有法人资质的人工智能社会团体。联盟以助推人工智能与实体经济的深度融合，推动政产学研用一体化发展为己任，致力于“推动产业协同发展，赋能地方产业转型升级，精准服务全国企业，加速产业技术应用落地”。</p>
        <p>自成立以来，联盟认真履行“服务、引导、协调、规范”的工作职能，会员单位现已涵盖小米集团、凌云光、九章云极、中国信通院等企业、科研院所300余家，人工智能头部企业100余家；聘请中国工程院院士、原教育部副部长赵沁平担任总顾问，中国科学院院士、中国工程院资深院士、国际欧亚科学院院士李德仁，中国工程院院士中央网信办专家咨询委员会顾问沈昌祥、中国工程院院士谭建荣、中国工程院院士丁文华为特邀专家，为联盟发展提供战略指导；前小米集团副总裁、小米集团技术委员会主席、国际开源领域知名专家崔宝秋担任理事长，引领联盟迈向新的发展阶段。联合业界知名企业、高校科研院校等机构，共同成立数字人、数字化转型、XR、智慧园区、信息技术创新应用、RPA、知识产权、投融资、教育、人工智能大模型、数据要素服务、智能教育、算力13个工作委员会。</p>
        <p><span class="b">完善创新创业生态</span>，建设运营人工智能大模型产业集聚区和中关村虚拟现实产业园，搭建人工智能大模型数据服务平台、中关村人工智能大模型应用人才培养基地，依托国家级院士、中科院等优质资源，建立专家服务功能体系，为人工智能产业发展提供专业咨询、指导和智力支持。<span class="b">发布行业标准和产业研究</span>，连续四年发布数字生态指数-人工智能分指数（2020-2023），协同产业多家机构、企业共同发布包含花样滑冰、信息技术应用创新、智慧园区等领域的20项团体标准，为产业发展提供保障。开展“XR企业TOP100”评价、“大模型TOP50强”榜单评选、发布《2023中国XR企业TOP100发展报告》《2024中国大模型企业TOP50发展报告》《扩展现实（XR）产业和标准化研究报告（2024版）》，推动行业和企业健康有序发展，助力数字经济做强、做优、做大。<span class="b">开展专业产业活动</span>，协同政府部门、龙头企业、科研院所共举办产业峰会、沙龙、培训、科普传播、座谈交流等上百场产业活动，为人工智能产业上下游企业搭建了交流合作、信息共享平台。</p>
<!--        <p>-->
<!--          中关村数智人工智能产业联盟充分发挥平台自身的专家智库资源优势，为小微企业提供与院士、产业专家等学术大咖交流的机会；搭建与高校、科研院所沟通的平台，提供专家政策咨询、高新政策解读等对接。</p>-->
<!--        <p>-->
<!--          建立专利风险防范和互惠机制，建立公共专利池和与标准相关的知识产权政策，促进小微企业知识产权与研发生产基础设施的共享，开展共性关键技术的联合攻关。为企业提供人力资源、法律、知识产权、财务管理等多维度的企业管理相关支持。</p>-->
        <img src="https://30093654.s21i.faiusr.com/4/ABUIABAEGAAgm-_lqgYo_sD07wcwkAg41AI.png.webp" alt="">
      </div>
      <div class="introduce">
        <div class="title">员工风采</div>
        <div class="en">STAFF</div>
      </div>

      <div style="width:100%;max-width:1200px;display: flex">
        <div class="img-l">
          <div class="l-t">
            <img src="https://30093654.s21i.faiusr.com/2/ABUIABACGAAg2sWrqgYomMixATCgCzi4CA!400x400.jpg.webp" alt="">
            <img src="https://30093654.s21i.faiusr.com/2/ABUIABACGAAg2sWrqgYorY7uyQIwoAs4uAg!400x400.jpg.webp" alt="">
          </div>
          <img style="width: 780px;height: 467px;margin-top: 55px" src="https://30093654.s21i.faiusr.com/4/ABUIABAEGAAg2sWrqgYoxIeRqwUwoQY43wM!800x800.png.webp" alt="">
        </div>
        <div class="img-r">
          <img style="height: 467px;" src="https://30093654.s21i.faiusr.com/2/ABUIABACGAAg6unGqgYoz8CLGTC4CDizCg!500x500.jpg.webp" alt="">
          <img style="margin-top: 15px" src="https://30093654.s21i.faiusr.com/2/ABUIABACGAAg2sWrqgYo2LqitQEwuAg4gwg!400x400.jpg.webp" alt="">
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import BannerTop from "@/pages/home/comments/bannerTop/index.vue";

export default {
  name: "about",
  components: {BannerTop},
  data() {
    return {
      topInfo:{
        title:'关于我们',
        description:'走进开元数智 | 加入开元数智',
        imgUrl:'https://30093654.s21i.faiusr.com/2/ABUIABACGAAg-6_nqgYogO2FjgcwgAo4pAM.jpg.webp'
      },
    }
  }
}
</script>


<style scoped lang="scss">
.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 35px;
  p{
    margin: 5px 0;
  }
}

.introduce {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 25px 0;
}

.title {
  font-size: 26px;
}

.en {
  font-size: 16px;
  margin-top: 10px;
  color: rgb(169, 169, 169);
}

.content {
  width: 100%;
  max-width: 1200px;
  font-size: 16px;
  line-height: 1.5;
  color: #666666;

  img {
    width: 100%;
    margin: 15px 0;
  }

  .title {
    font-weight: bold;
    color: #4c4948;
    font-size: 16px;
  }
}
.img-l{
  display: flex;
  flex-direction: column;

  margin-right: 15px;
}
.img-r{
  display: flex;
  flex-direction: column;
  img{
    width: 360px;
  }
}
.l-t{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  img{
    width: 380px;
    height: 285px;
  }
}
.b{
  font-weight: bold;
}
</style>