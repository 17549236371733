var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ul',{staticClass:"ulStyle"},[(_vm.currentNum()==='1')?_c('li',[_vm._m(0)]):_vm._e(),(_vm.currentNum()==='1')?_c('li',[_vm._m(1)]):_vm._e(),(_vm.currentNum()==='1')?_c('li',[_vm._m(2)]):_vm._e(),(_vm.currentNum()==='1')?_c('li',[_vm._m(3)]):_vm._e(),(_vm.currentNum()==='2')?_c('li',[_vm._m(4)]):_vm._e(),(_vm.currentNum()==='2')?_c('li',[_vm._m(5)]):_vm._e(),(_vm.currentNum()==='3')?_c('li',[_vm._m(6)]):_vm._e(),_vm._v(" "),(_vm.currentNum()==='3')?_c('li',[_vm._m(7)]):_vm._e(),(_vm.currentNum()==='3')?_c('li',[_vm._m(8)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/d.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("场景创建")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/d2.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("效果调优")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/d3.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("分流实验")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/d4.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("最终优化")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/d4.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("应用新策略")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/d3.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("库存计划")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/d4.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("标注数据集")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/d3.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("标注平台")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/d2.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("FTP上传")])])
}]

export { render, staticRenderFns }