var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ul',{staticClass:"ulStyle"},[(_vm.currentNum()==='1')?_c('li',[_vm._m(0)]):_vm._e(),(_vm.currentNum()==='1')?_c('li',[_vm._m(1)]):_vm._e(),(_vm.currentNum()==='1')?_c('li',[_vm._m(2)]):_vm._e(),(_vm.currentNum()==='1')?_c('li',[_vm._m(3)]):_vm._e(),(_vm.currentNum()==='2')?_c('li',[_vm._m(4)]):_vm._e(),(_vm.currentNum()==='2')?_c('li',[_vm._m(5)]):_vm._e(),(_vm.currentNum()==='2')?_c('li',[_vm._m(6)]):_vm._e(),(_vm.currentNum()==='3')?_c('li',[_vm._m(7)]):_vm._e(),(_vm.currentNum()==='3')?_c('li',[_vm._m(8)]):_vm._e(),(_vm.currentNum()==='4')?_c('li',[_vm._m(9)]):_vm._e(),(_vm.currentNum()==='4')?_c('li',[_vm._m(10)]):_vm._e(),(_vm.currentNum()==='4')?_c('li',[_vm._m(11)]):_vm._e(),(_vm.currentNum()==='5')?_c('li',[_vm._m(12)]):_vm._e(),(_vm.currentNum()==='5')?_c('li',[_vm._m(13)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/s.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("日常管理")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/s2.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("系统相关场景")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/s3.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("配置能力")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/s4.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("多种策略")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/s4.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("商品信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/s3.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("销量预测")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/s2.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("补货计划")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/s4.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("标注平台")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/s3.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("第三方数据标注")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/s4.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("历史销售数据")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/s3.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("销售计划")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/s2.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("库存结构")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/s4.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("结构化")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/s3.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("任务管理")])])
}]

export { render, staticRenderFns }