<template>
  <div class="hello">
    <div class="tac">
      <div class="logo">
        <img src="./img/lcyz.jpg" alt="">
      </div>
      <el-menu
          :default-active="activeMenu"
          class="el-menu-vertical-demo"
          router
          @open="handleOpen"
          @close="handleClose"
      >

<!--        1-->
        <el-menu-item v-if="currentNum()==='1'" index="/hello/sightMangement">
          <i class="el-icon-menu"></i>
          <span slot="title">场景管理</span>
        </el-menu-item>
        <el-menu-item v-if="currentNum()==='1'" index="/hello/sightMoreMangement">
          <i class="el-icon-menu"></i>
          <span slot="title">场景多方案管理</span>
        </el-menu-item>
        <el-menu-item v-if="currentNum()==='1'" index="/hello/groupMangement">
          <i class="el-icon-document"></i>
          <span slot="title">分群管理</span>
        </el-menu-item>
        <el-menu-item v-if="currentNum()==='1'" index="/hello/experimentPlatform">
          <i class="el-icon-setting"></i>
          <span slot="title">实验平台</span>
        </el-menu-item>

        <!--        2-->
        <el-menu-item v-if="currentNum()==='2'"   index="/hello/sightMangement">
          <i class="el-icon-menu"></i>
          <span slot="title">补货执行</span>
        </el-menu-item>
        <el-menu-item v-if="currentNum()==='2'"  index="/hello/sightMoreMangement">
          <i class="el-icon-menu"></i>
          <span slot="title">补货优化</span>
        </el-menu-item>
<!--        3-->
        <el-menu-item v-if="currentNum()==='3'" index="/hello/sightMangement">
          <i class="el-icon-menu"></i>
          <span slot="title">数据标注</span>
        </el-menu-item>
        <el-menu-item v-if="currentNum()==='3'" index="/hello/sightMoreMangement">
          <i class="el-icon-menu"></i>
          <span slot="title">数据上传</span>
        </el-menu-item>
        <el-menu-item v-if="currentNum()==='3'" index="/hello/groupMangement">
          <i class="el-icon-document"></i>
          <span slot="title">模型训练</span>
        </el-menu-item>
        <el-menu-item v-if="currentNum()==='3'" index="/hello/experimentPlatform">
          <i class="el-icon-setting"></i>
          <span slot="title">创建应用</span>
        </el-menu-item>

<!--        4-->
        <el-menu-item v-if="currentNum()==='4'" index="/hello/sightMangement">
          <i class="el-icon-setting"></i>
          <span slot="title">销售预测</span>
        </el-menu-item>


        <el-menu-item v-if="currentNum()==='4'" index="/hello/groupMangement">
          <i class="el-icon-document"></i>
          <span slot="title">智能补货</span>
        </el-menu-item>
        <el-menu-item v-if="currentNum()==='4'" index="/hello/experimentPlatform">
          <i class="el-icon-setting"></i>
          <span slot="title">多级库存优化</span>
        </el-menu-item>

<!--5-->
        <el-menu-item v-if="currentNum()==='5'" index="/hello/sightMangement">
          <i class="el-icon-setting"></i>
          <span slot="title">数据引入</span>
        </el-menu-item>


        <!--        <el-menu-item index="/hello/groupMangement">-->
<!--          <i class="el-icon-document"></i>-->
<!--          <span slot="title">分群管理</span>-->
<!--        </el-menu-item>-->
<!--        <el-menu-item index="/hello/experimentPlatform">-->
<!--          <i class="el-icon-setting"></i>-->
<!--          <span slot="title">实验平台</span>-->
<!--        </el-menu-item>-->

        <!--        3-->
<!--        <el-menu-item index="/hello/sightMangement">-->
<!--          <i class="el-icon-menu"></i>-->
<!--          <span slot="title">场景管理</span>-->
<!--        </el-menu-item>-->
<!--        <el-menu-item index="/hello/sightMoreMangement">-->
<!--          <i class="el-icon-menu"></i>-->
<!--          <span slot="title">场景多方案管理</span>-->
<!--        </el-menu-item>-->
<!--        <el-menu-item index="/hello/groupMangement">-->
<!--          <i class="el-icon-document"></i>-->
<!--          <span slot="title">分群管理</span>-->
<!--        </el-menu-item>-->
<!--        <el-menu-item index="/hello/experimentPlatform">-->
<!--          <i class="el-icon-setting"></i>-->
<!--          <span slot="title">实验平台</span>-->
<!--        </el-menu-item>-->

        <!--        4-->
<!--        <el-menu-item index="/hello/sightMangement">-->
<!--          <i class="el-icon-menu"></i>-->
<!--          <span slot="title">场景管理</span>-->
<!--        </el-menu-item>-->
<!--        <el-menu-item index="/hello/sightMoreMangement">-->
<!--          <i class="el-icon-menu"></i>-->
<!--          <span slot="title">场景多方案管理</span>-->
<!--        </el-menu-item>-->
<!--        <el-menu-item index="/hello/groupMangement">-->
<!--          <i class="el-icon-document"></i>-->
<!--          <span slot="title">分群管理</span>-->
<!--        </el-menu-item>-->
<!--        <el-menu-item index="/hello/experimentPlatform">-->
<!--          <i class="el-icon-setting"></i>-->
<!--          <span slot="title">实验平台</span>-->
<!--        </el-menu-item>-->

        <!--        5-->
<!--        <el-menu-item index="/hello/sightMangement">-->
<!--          <i class="el-icon-menu"></i>-->
<!--          <span slot="title">场景管理</span>-->
<!--        </el-menu-item>-->
<!--        <el-menu-item index="/hello/sightMoreMangement">-->
<!--          <i class="el-icon-menu"></i>-->
<!--          <span slot="title">场景多方案管理</span>-->
<!--        </el-menu-item>-->
<!--        <el-menu-item index="/hello/groupMangement">-->
<!--          <i class="el-icon-document"></i>-->
<!--          <span slot="title">分群管理</span>-->
<!--        </el-menu-item>-->
<!--        <el-menu-item index="/hello/experimentPlatform">-->
<!--          <i class="el-icon-setting"></i>-->
<!--          <span slot="title">实验平台</span>-->
<!--        </el-menu-item>-->
      </el-menu>
    </div>
    <div class="right">
      <div class="title">
        {{titleName[currentNum()]}}
      </div>
      <div style="height: calc(100% - 100px)">
        <router-view></router-view>  <!-- 子路由渲染区域 -->
      </div>
    </div>
  </div>
</template>

<script>
import {currentNum, titleName} from '@/utils/data'
import {defineComponent} from "vue";

export default defineComponent({
  computed: {
    titleName() {
      return titleName
    }
  },
  methods: {
    currentNum() {
      return currentNum
    }
  }
})

</script>

<style scoped>
.hello {
  display: flex;
}

.tac {
  width: 200px;
  height: 100vh;
}

.right {
  width: calc(100vw - 210px);
  margin-left: 10px;
  height: 100vh;
  background-color: #f7f9fe;
}

.el-menu-vertical-demo {
  height: calc(100% - 55px);

}


.title {
  font-size: 30px;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.logo {
  padding-top: 5px;
  display: flex;
  justify-content: center;
  align-content: center!important;
  img{
    width: 90%;
    height: 90%;
  }
  width: 100%;
  height: 50px;
}
</style>