<template>
  <div >
    <!--  头部-->
    <header class="header-top">
      <div class="left-style">
        <div class="logo">
          <img :src="logoImg" alt="" v-if="logoImg">
          <img src="./img/img_3.png" alt="" class="img" v-else>
        </div>
        <ul class="titleWord">
          <li :class="[{ 'activeStyle': currentActive === 'home' }]" @click="goOtherPage('home')">首页</li>

          <li :class="[{ 'activeStyle': currentActive === 'suanShop' }]" @click="goOtherPage('suanShop')">算力市场</li>
          <li :class="[{ 'activeStyle': currentActive === 'zs' }]" @click="goOtherPage('zs')">智算中心</li>
          <li :class="[{ 'activeStyle': currentActive === 'dmx' }]" @click="goOtherPage('dmx')">大模型应用</li>
          <li :class="[{ 'activeStyle': currentActive === 'cxy' }]" @click="goOtherPage('cxy')">产学研</li>
          <li :class="[{ 'activeStyle': currentActive === 'aboutUs' }]" @click="goOtherPage('aboutUs')">关于我们</li>
        </ul>
      </div>
      <div class="right-style">
        <span class="download"></span>
        <el-button @click="login" class="loginBtn-style" style="color: white;">登录</el-button>
<!--        <el-button @click="register" class="register-style">注册</el-button>-->
      </div>
    </header>

    <!--  轮播图-->
<!--    <div class="banner">-->
<!--      <el-carousel :interval="50000" arrow="always" style="height: 6rem;">-->
<!--        <el-carousel-item style="height: 6rem;" class="banner-item">-->
<!--          <img src="../../assets/kyy/banner01.png">-->
<!--        </el-carousel-item>-->
<!--        <el-carousel-item style="height: 6rem;" class="banner-item">-->
<!--          <img src="../../assets/kyy/temporaryPic/bann2.png">-->
<!--        </el-carousel-item>-->
<!--        <el-carousel-item style="height:6rem;" class="banner-item">-->
<!--          <img src="../../assets/kyy/temporaryPic/banner5.jpg">-->
<!--        </el-carousel-item>-->
<!--      </el-carousel>-->
<!--    </div>-->
<!--    <div class="clearFloat"></div>-->
<!--    <div style="display: none">-->
<!--      <ul style="display: flex;justify-content: space-between;margin-top: 1.333rem;padding-left:0;">-->
<!--        <li class="content-footer-item"-->
<!--            style="padding-left:0;display: flex;flex-direction:row;justify-content: center;align-items: center">-->
<!--          <img class="icon-footer" src="../../assets/kyy/Icon-kexuejishu-.svg" alt="">-->
<!--          <div style="display: flex;flex-direction: column;justify-content: center;align-items: center">-->
<!--            <span class="footer-title" style="cursor: pointer"-->
<!--                  @click="goProduct('http://tmpweb.opencomputing.cn/col.jsp?id=146')">公有云</span>-->
<!--            &lt;!&ndash;            <span class="footer-content">算力互联网络和算力调度核心技术</span>&ndash;&gt;-->
<!--          </div>-->

<!--        </li>-->
<!--        <li class="content-footer-item"-->
<!--            style="display: flex;flex-direction:row;justify-content: center;align-items: center">-->
<!--          <img class="icon-footer" src="../../assets/kyy/Icon-_khgx.svg" alt="">-->
<!--          <div style="display: flex;flex-direction: column;justify-content: center;align-items: center">-->
<!--            <span class="footer-title" style="cursor: pointer"-->
<!--                  @click="goProduct('http://tmpweb.opencomputing.cn/col.jsp?id=138')">超算</span>-->
<!--            &lt;!&ndash;            <span class="footer-content" >中立的全国性网络资源算力中心资源</span>&ndash;&gt;-->
<!--          </div>-->
<!--        </li>-->
<!--        <li class="content-footer-item"-->
<!--            style="display: flex;flex-direction:row;justify-content: center;align-items: center">-->
<!--          <img class="icon-footer" src="../../assets/kyy/Icon-a-01changjing.svg" alt="">-->
<!--          <div style="display: flex;flex-direction: column;justify-content: center;align-items: center">-->
<!--            <span class="footer-title" style="cursor: pointer"-->
<!--                  @click="goProduct('http://tmpweb.opencomputing.cn/col.jsp?id=139')">智算</span>-->
<!--            &lt;!&ndash;            <span class="footer-content">面向行业<br/>&ndash;&gt;-->
<!--            &lt;!&ndash;面向应用</span>&ndash;&gt;-->
<!--          </div>-->
<!--        </li>-->
<!--        <li class="content-footer-item"-->
<!--            style="display: flex;flex-direction:row;justify-content: center;align-items: center">-->
<!--          <img class="icon-footer" src="../../assets/kyy/Icon-yunyingzhongxin.svg" alt="">-->
<!--          <div style="display: flex;flex-direction: column;justify-content: center;align-items: center">-->
<!--            <span class="footer-title" style="cursor: pointer"-->
<!--                  @click="goProduct('http://tmpweb.opencomputing.cn/col.jsp?id=140')">网络</span>-->
<!--            &lt;!&ndash;            <span class="footer-content">多年运营服务经验 <br/>市场客户沉淀</span>&ndash;&gt;-->
<!--          </div>-->
<!--        </li>-->

<!--      </ul>-->
<!--    </div>-->

<!--    &lt;!&ndash;    核心业务&ndash;&gt;-->
<!--    <div class="business">-->
<!--      <span class="business-title" style="margin-bottom: 0.2rem;margin-top: 1.133rem">核心业务</span>-->
<!--      <span class="business-content"-->
<!--            style="margin-bottom: 0.2rem">提供领先、可靠、多场景的应用解决方案安全、稳定、确定</span>-->
<!--      &lt;!&ndash;      四个li&ndash;&gt;-->
<!--      <ul style="display: flex;justify-content: center;padding-left: 0">-->
<!--        <li class="content-footer-item">-->
<!--          <img style="width: 2.667rem;height: 2.667rem;" src="../../assets/kyy/temporaryPic/w1.png" alt="">-->
<!--          <span class="footer-title">智算中心</span>-->
<!--          <span class="footer-content">智算中心咨询-->
<!--              /规划/建设/运营/运维</span>-->
<!--        </li>-->
<!--        <li class="content-footer-item">-->
<!--          <img style="width: 2.667rem;height: 2.667rem;" src="../../assets/kyy/temporaryPic/w2.png" alt="">-->
<!--          <span class="footer-title">算力网络</span>-->
<!--          <span class="footer-content">云互联、超算互联、智算互联</span>-->
<!--        </li>-->
<!--        <li class="content-footer-item">-->
<!--          <img style="width: 2.667rem;height: 2.667rem;" src="../../assets/kyy/temporaryPic/w3.png" alt="">-->
<!--          <span class="footer-title">算力服务</span>-->
<!--          <span class="footer-content">融合云、HPC、AI算力、信创</span>-->
<!--        </li>-->
<!--        <li class="content-footer-item">-->
<!--          <img style="width: 2.667rem;height: 2.667rem;" src="../../assets/kyy/temporaryPic/w4.png" alt="">-->
<!--          <span class="footer-title">应用解决方案</span>-->
<!--          <span class="footer-content">企业多场景云服务、高校科学计算、大模型架构及应用</span>-->
<!--        </li>-->
<!--      </ul>-->
<!--      <span class="business-title" style="margin-top: 0.3rem;margin-bottom: 0.2rem">深入业务场景的算网解决方案</span>-->
<!--      <span class="business-content" style="margin-bottom: 0.333rem">以高水平算网调度解决方案助力各行业高质量发展</span>-->
<!--      &lt;!&ndash;      图片&ndash;&gt;-->
<!--      <div>-->
<!--        <img class="business-pic" style="width: 16rem;height: 4rem;" src="../../assets/kyy/深入方案bg.png">-->
<!--      </div>-->
<!--    </div>-->





<!--    &lt;!&ndash;    企业资讯&ndash;&gt;-->
<!--    <div class="business" style="margin-top: 3rem">-->
<!--      <span class="business-title" style="margin-top: 0.667rem;margin-bottom: 0.2rem">企业资讯</span>-->
<!--      <div>-->
<!--        <img @click="goNewsPage" style="height: 10.667rem;width: 16rem;cursor: pointer" class="business-pic"-->
<!--             src="../../assets/kyy/temporaryPic/ff.png">-->
<!--      </div>-->
<!--    </div>-->

<!--    &lt;!&ndash;    核心业务&ndash;&gt;-->
<!--    <div class="business" style="height: 7.333rem;">-->
<!--      <span class="business-title" style="margin-bottom: 0.2rem;margin-top: 0.467rem">全国高校算力网络创新论坛</span>-->
<!--      <span class="business-content" style="width: 16rem;margin-bottom: 0.333rem">由江苏未来网络集团主办、中国高等教育学会教育信息化分会指导、开元云（北京）科技有限公司协办的全国高校算力网络创新论坛成功举办，该论坛聚焦高校数字化转型背景下，针对高校科研领域的新要求，分享算力网络最新的前沿技术，共话算力网络技术与服务企业产业布局，研讨高校科研需求与算力技术的供应。</span>-->
<!--      &lt;!&ndash;      图片&ndash;&gt;-->
<!--      <div style="display: flex;justify-content: center;align-items: space-between;padding-top: 0.2rem" class="ckl">-->
<!--        <div style="width: 4.667rem;height: 4rem;">-->
<!--          <img src="../../assets/kyy/temporaryPic/640.jpg" alt=""/>-->
<!--        </div>-->
<!--        <div style="width: 4.667rem;height: 4rem;">-->
<!--          <img src="../../assets/kyy/temporaryPic/640(1).jpg" alt=""/>-->
<!--        </div>-->
<!--        <div style="width: 4.667rem;height: 4rem;">-->
<!--          <img src="../../assets/kyy/temporaryPic/643.png" alt=""/>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->


<router-view></router-view>
    <!--    欢迎使用-->
    <div class="like">
      <img src="../../assets/kyy/编组_10.png" alt="">
      <div class="pic-word">
        <span class="hiTitle">欢迎体验</span>
        <span class="goLogin-style" @click="login">立即体验</span>
      </div>
    </div>

    <div class="footer-top-style">
      <div class="item-footer-top">
        <div style="display: flex;justify-content: center;align-items: center">
          <img src="../../assets/kyy/temporaryPic/Icon-1.png" alt="">
        </div>
        <span class="resource">资源全覆盖</span>
      </div>

      <div class="item-footer-top">
        <div style="display: flex;justify-content: center;align-items: center">
          <img src="../../assets/kyy/temporaryPic/Icon-2.png" alt="">
        </div>
        <span class="resource">体验极速</span>
      </div>
      <div class="item-footer-top">
        <div style="display: flex;justify-content: center;align-items: center">
          <img src="../../assets/kyy/temporaryPic/Icon-3.png" alt="">
        </div>
        <span class="resource">用户满意</span>
      </div>
      <div class="item-footer-top">
        <div style="display: flex;justify-content: center;align-items: center">
          <img src="../../assets/kyy/temporaryPic/Icon-4.png" alt="">
        </div>
        <span class="resource">省心计算</span>
      </div>
    </div>
    <!--    footer-->
    <div class="footer">
      <div class="left-box">
   <div style="display: flex;justify-content: flex-start;align-items: center;width: 100%;">
     <div class="logo-footer">
       <img :src="logoImg" alt="" v-if="logoImg">
       <img src="../../assets/kyy/LOGO.png" alt="" class="img" v-else>
     </div>
     <div class="content-main">
       <ul>
         <li>地址:{{ address }}</li>
         <li>电话：400-6150805 &nbsp;&nbsp;&nbsp;&nbsp; <span style="margin-left: 0.267rem">010-65917875 </span>
         </li>
         <li>邮箱：sale@ncmatch.cn</li>
         <li>IPC备案号:{{ ICP }} <span style="margin-left: 0.267rem">版权所有 ©️2023 开元数智（北京）科技有限公司</span></li>
         <li  style="display: flex;justify-content: flex-start;align-items: center;">
           <img src="./img/policeInsignia.png" alt=""
                style="width:0.227rem;height:0.227rem;margin-right: 0.027rem;">
           <a href="https://beian.mps.gov.cn/#/query/webSearch?code=11010502054007" rel="noreferrer" target="_blank"
              style="margin-right:0.4rem;">京公网安备11010502054007</a>
           <span>
                  <router-link tag="a" target="_blank"
                               :to="{ name: 'homePageImage' }">经营许可证:京B2-20232313</router-link>
                </span>
         </li>
       </ul>
     </div>
   </div>
        <div  class="intrudceMessage">
          <span style="margin-left: 2em">开元数智(北京)科技有限公司是一家注册于2022年的高科技企业，在上海、南京、深圳、济南设有分支机构。公司通过自主研发的开元算力应用服务平台，将算力资源和算力应用进行整合，利用算力调度和确定性
          </span>
          <span>网络技术，为科研、XR、数字孪生和AI等行业提供专业算力服务，形成“算力服务+算力网络+算力应用”的一体化解决方案。</span>
        </div>
      </div>
      <div  class="right-box">
        <div class="qr-box">
          <div class="qr-code">
            <img  src="../../assets/kyy/客服wechat.png" style="padding: 0.08rem;width: 100px;
height: 100px;" alt="">
          </div>
          <span class="qr-content">微信客服</span>
        </div>
        <div class="qr-box" style="margin-left: 0.667rem">
          <div class="qr-code">
            <img src="../../assets/kyy/kyy公众号.jpg" alt="">
          </div>
          <span class="qr-content">扫描关注二维码</span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {goUrl} from "@/utils/settings";

export default {
  name: "indexNew",
  data() {
    return {
      currentActive:'home',
      myChart: null,
      logoImg: require("../../assets/logo/logo2.png"),
      // logoImg: require("../../assets/logo/tt.jpg"),
      // logoText: "开元云(北京)科技有限公司",
      logoText: "开元数智(北京)科技有限公司",
      url: window.location.href,
      photos: [],
      photosUrl: [],
      ICP: "京ICP备2022001945号-1",
      // address: "农业展览馆13号瑞辰国际中心518B(团结湖地铁站c东南口步行420米)",
      address: "北京市石景山区和平西路60号院1号楼11层1101-30",
      selectData: [],
      fileUrl: null,
      fileName: null,
      title: null,
    }
  },
  created() {
    this.init()
  },
  mounted() {
    this.$nextTick(() => {
      // this.initEchartMap();
      // this.initEcahrtkk()
      // this.initEcahrtkk1()


    })
  },
  methods: {
    goNewsPage() {
      // window.open('http://tmpweb.opencomputing.cn/col.jsp?id=136')
      window.open(goUrl)
    },
    login() {
      window.open(goUrl)
    },
    register() {
      this.$router.push('/registrationPage')
    },
    goOtherPage(value) {
      this.currentActive = value
      if (value === 'suanShop') {
  this.$router.push('/home/suanShop')
      } else if (value === 'aboutUs') {
        this.$router.push('/home/about')
      } else if (value === 'dmx') {
        this.$router.push('/home/dmx')
      } else if (value === 'zs') {
        this.$router.push('/home/zs')

      }else if (value === 'cxy') {
        this.$router.push('/home/cxy')

      }else if (value === 'home') {
        this.$router.push('/home/index')

      }
    },


  }
}
</script>

<style scoped lang="scss">
.left-style {
  display: flex;
  justify-content: flex-start;
}

.header-top {
  box-shadow: 0 .5rem 1rem #12263f0d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  z-index: 99;
  background: #fff;
}

ul {
  list-style: none;
}

//

.logo {

  background-color: #32abfc;
  margin-left: 0.467rem;
  width: 2.5rem;
  height: 0.6rem;
  //margin-top: -0.4rem;
  margin-right: 25px;
display: flex;
  justify-content: center;
  align-items: center;
  .img {
    width: 100%;
    height: 100%;

  }

  img {
    padding: 0;
    margin: 0;
  }
}

.titleWord {
  font-weight: 550;
  mix-blend-mode: normal;
  text-align: center;
  color: rgba(0, 0, 0, 1);
  font-family: "Microsoft YaHei", serif;
  font-size: 16px;
  color: #4c4948;
  display: flex;
  justify-content: flex-start;
  align-items:center;

  li {
    cursor: pointer;
    margin-right: 0.8rem;
    transition: all .3s ease-in-out;
    &:hover{
      transition: all .3s ease-in-out;
      color: #6969e3;
    }
  }
}

.right-style {
  margin-top: -0.533rem;

  .download {
    padding-right: 0.133rem;
    color: #909090;
  }
}

.loginBtn-style {
  color: black;
  font-size: 16px;
  font-weight: bold;
  mix-blend-mode: normal;
  border-radius: 0.32rem;
  background: linear-gradient(
          132.63deg,
          rgba(17, 93, 245, 1) 2.35%,
          rgba(0, 117, 252, 1) 94.2%
  );
  padding: 10px 18px;
  margin-right: 10px;
}

.register-style {
  font-size: 0.187rem;
  mix-blend-mode: normal;
  color: rgba(144, 144, 144, 1);
  font-family: PingFang SC, serif;
  font-weight: 600;
  margin-right: 0.067rem;
  padding: 0.2rem;
}

//轮播图
.banner {
  position: relative;
  //width: 1200px;
  width: 100%;
  height: 4.8rem;
  margin: 0 auto;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 0.24rem;
  opacity: 0.75;
  line-height: 4rem;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.banner-item {
  img {
    width: 100%;
    height: 100%;
  }
}

.title-word-style {
  mix-blend-mode: normal;
  color: rgba(0, 0, 0, 1);
  font-family: PingFang SC, serif;
  font-weight: 600;
  font-size: 0.427rem;
}

.content-style {
  mix-blend-mode: normal;
  color: rgba(98, 98, 98, 1);
  font-family: PingFang SC, serif;
  font-weight: 600;
  font-size: 0.16rem;
}

.banner-all {
  z-index: 9;
  position: absolute;
  top: 25%;
  left: 10%;
  display: flex;
  flex-direction: column;
  width: 4rem;
}

//实施算网融合发展行动
.action {
  position: relative;
  height: 11.573rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  img {
    width: 80%;
    height: 50%;
  }
}

.clearFloat {
  clear: both;
}

.top-right {
  position: absolute;
  right: 0;
  top: 0;
}

.top-left {
  position: absolute;
  left: 0;
  bottom: 0;
}

.action-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  margin: 0 auto;
  width: 80%;
  height: 100%;
}

.content-title-before {
  mix-blend-mode: normal;
  border: 0.04rem solid rgba(255, 255, 255, 1);
  text-align: center;
  color: rgba(0, 0, 0, 1);
  font-family: PingFang SC, serif;
  font-weight: 600;
  font-size: 0.4rem;
}

.content-title-last {
  mix-blend-mode: normal;
  border: 0.04rem solid rgba(255, 255, 255, 1);
  text-align: center;
  font-family: PingFang SC, serif;
  font-weight: 600;
  font-size: 0.4rem;
  color: rgba(17, 93, 245, 1);
}

.num {
  //margin-top: px;
  width: 35%;

  height: 6.667rem;
}

.pic-right {
  width: 40%;
  height: 100%;
  //background-color: skyblue;
  display: flex;
  flex-direction: column;

  img {
    margin: 0.2rem 0;
    margin-left: 2rem;
    width: 2.667rem;

    height: 2.667rem;
  }
}

.content-main {
  display: flex;
  justify-content: center;
  align-items: center;

  ul {
    li {
      margin-bottom: 0.2rem;
      cursor: default;
    }
  }
}

.content-item {
  margin-bottom: 0.2rem;
  width: 4.587rem;
  height: 1.253rem;
  mix-blend-mode: normal;
  border-radius: 0rem 0.107rem 0.107rem 0.107rem;
  border: 0.013rem solid rgba(239, 239, 239, 1);
  box-sizing: border-box;
  position: relative;
}

.little-item {
  width: 4.587rem;
  height: 1.253rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 0.267rem;
}

.h-word {
  mix-blend-mode: normal;
  color: rgba(0, 0, 0, 1);
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 0.213rem;
}

.c-word {
  mix-blend-mode: normal;
  color: rgba(138, 138, 138, 1);
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 0.16rem;
  margin-top: 0.067rem;
}

.num-icon {
  position: absolute;
  top: 0.04rem;
  left: -0.24rem;
  width: 30%;
  height: 1.067rem;
}

.icon-footer {
  width: 0.64rem;
  height: 0.64rem;
}

.content-footer-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0.733rem;

  img {
    width: 1.12rem;
    height: 0.64rem;
    margin: 0.2rem 0;
  }
}

.footer-title {
  mix-blend-mode: normal;
  color: rgba(0, 0, 0, 1);
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 0.28rem;
}

.footer-content {
  margin-top: 5px;
  mix-blend-mode: normal;
  text-align: center;
  color: rgba(138, 138, 138, 1);
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 0.187rem;
  width: 1.8rem;
}

//核心业务
.business {
  width: 100%;
  height: 10.893rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.business-title {
  mix-blend-mode: normal;
  text-align: center;
  color: rgba(0, 0, 0, 1);
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 0.4rem;
}

.business-content {
  mix-blend-mode: normal;
  text-align: center;
  color: rgba(138, 138, 138, 1);
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 0.213rem;
}

.business-icon-footer {
}

.business-pic {
  width: 13.867rem;
  height: 4.8rem;
}

//高校网络
.shcool {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.shcool-main {
  position: relative;
  width: 16.16rem;
  height: 8.213rem;

  img {
    width: 100%;
    height: 100%;
  }
}

.left-content {
  position: absolute;
  top: 0.667rem;
  left: 0.333rem;
  width: 6.667rem;
  height: 6.667rem;
}

.c-one {
  position: absolute;
  top: 0.667rem;
  left: 0;
  width: 2.04rem;
  height: 2.04rem;
  border-radius: 50%;
  background: rgba(255, 169, 0, 0.74);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.school-title {
  mix-blend-mode: normal;
  text-align: center;
  color: rgba(161, 67, 0, 1);
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 0.213rem;
}

.school-school {
  mix-blend-mode: normal;
  text-align: center;
  color: rgba(161, 67, 0, 1);
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 0.48rem;
}

.c-two {
  position: absolute;
  top: 0;
  left: 1.6rem;
  width: 1.56rem;
  height: 1.56rem;
  border-radius: 50%;
  background: rgba(0, 119, 255, 0.74);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.school-title-two {
  font-size: 0.213rem;
}

.c-thr {
  position: absolute;
  top: 1.24rem;
  left: 1.853rem;
  width: 1.173rem;
  height: 1.173rem;
  border-radius: 50%;
  background: rgba(0, 155, 24, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.top-school {
  position: relative;
}

.tip-word {
  mix-blend-mode: normal;
  color: rgba(65, 70, 79, 1);
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 0.24rem;
}

.shcool-dscribtion {
  mix-blend-mode: normal;
  color: rgba(0, 0, 0, 1);
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 0.187rem;
}

.main-content {
  position: absolute;
  top: 3.333rem;
}

.main-title {
  mix-blend-mode: normal;
  color: rgba(65, 70, 79, 1);
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 0.24rem;
}

//欢迎体验
.like {
  position: relative;
  width: 100%;
  height: 3.8rem;

  img {
    width: 100%;
    height: 100%;
  }
}

.footer {
  width: 100%;
  height: 3.333rem;
  display: flex;
  justify-content: space-between;
  //flex-wrap: wrap;
  line-height: 1.5;

}

.left-box {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 45px;
}

.right-box {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-main {
  mix-blend-mode: normal;
  color: rgba(0, 0, 0, 1);
  font-family: PingFang SC;
  font-size: 0.187rem;
}

.logo-footer {
  width: 2.667rem;
margin-right: 25px;
  .img {
    width: 1.067rem;
    height: 1.067rem;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.qr-code {
  img {
    width: 110px;
    height: 110px;
  }

  width: 1.853rem;
  height: 1.853rem;
}

.qr-content {
  mix-blend-mode: normal;
  color: rgba(24, 24, 24, 1);
  font-family: PingFang SC, serif;
  font-weight: 600;
  font-size: 0.187rem;
}

.qr-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.logo {
  background-color: #32abfc;

  img {
    width: 100%;
    height: 100%;
  }
}

//ecahrt
.leftCard {
  width: 100%;
  height: 100%;

  #chinaMap {
    width: 100%;
    height: 100vh;
  }
}

.wrapper {
  width: 100%;
  border: 0.013rem solid red;
  height: 6.667rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.map-container {
  width: 16rem;
  height: 6.667rem;
  border: 0.013rem solid red;
}

.highlight {
  font-size: 1.667rem;
}

.custom-tooltip {
  width: 6.667rem;
  height: 6.667rem;
}

.pic-word {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hiTitle {
  font-size: 0.373rem;
  padding: 0.16rem;
  opacity: 0.8;
  mix-blend-mode: normal;

  color: #f35626;
  font-weight: 700;
  background: coral;
  background-image: -webkit-linear-gradient(
          45deg,
          rgba(17, 239, 222, 1),
          rgba(2, 117, 250, 1)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: hue 6s infinite linear;
}

.goLogin-style {
  &:hover {
    background-color: #aad1fd;
    color: white;
  }

  font-size: 0.32rem;
  cursor: pointer;
  color: #32abfc;
  width: 2.667rem;
  height: 0.56rem;
  opacity: 0.8;
  mix-blend-mode: normal;
  border-radius: 0.04rem;
  border: 0.013rem solid rgba(54, 147, 251, 1);
  box-sizing: border-box;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.news-block {
  padding: 0.027rem;
  width: 4.667rem;
  height: 4.267rem;
  border: 0.013rem solid #4191e0;
  position: absolute;
  top: 3.733rem;
  right: 0;
}

.pic-news {
  width: 2.667rem;
  height: 2.667rem;
}

.title-news {
  mix-blend-mode: normal;
  color: rgba(1, 119, 248, 1);
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 0.28rem;
}

.news-content {
  mix-blend-mode: normal;
  text-align: justify;
  color: rgba(0, 0, 0, 1);
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 0.187rem;
}

.ckl {
  img {
    width: 100%;
    height: 100%;
  }

  div {
    margin-right: 0.2rem;
  }
}

.footer-top-style {
  width: 16rem;
  height: 1.333rem;
  margin: 0.133rem auto 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.item-footer-top {
  img {
    width: 0.427rem;
    height: 0.427rem;
  }

  display: flex;
  justify-content: center;
  align-items: center;
}

.resource {
  mix-blend-mode: normal;
  color: rgba(0, 0, 0, 1);
  font-family: PingFang SC;
  font-size: 0.267rem;
  margin-left: 0.133rem;
}

.friends {
  width: 16rem;
  height: 2.667rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.2rem;
}

.logo-item-s {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  img {
    margin-right: 0.267rem;
    //border:1px solid red;
    width: 1.333rem;
  }

  width: 100%;
  height: 6rem;
}

.intrudceMessage {

  width: 16rem;
  height: 0.667rem;
  margin: 0 auto;
  font-size: 0.173rem;
  margin-bottom: 0.2rem;
  color: #878481;
}

::v-deep .el-carousel__container {
  height: 4.8rem;
}
.activeStyle{
  color: rgba(1, 119, 248, 1);
}
</style>
