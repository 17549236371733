var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ul',{staticClass:"ulStyle"},[(_vm.currentNum()==='1')?_c('li',[_vm._m(0)]):_vm._e(),(_vm.currentNum()==='1')?_c('li',[_vm._m(1)]):_vm._e(),(_vm.currentNum()==='1')?_c('li',[_vm._m(2)]):_vm._e(),(_vm.currentNum()==='1')?_c('li',[_vm._m(3)]):_vm._e(),(_vm.currentNum()==='1')?_c('li',[_vm._m(4)]):_vm._e(),(_vm.currentNum()==='3')?_c('li',[_vm._m(5)]):_vm._e(),(_vm.currentNum()==='3')?_c('li',[_vm._m(6)]):_vm._e(),(_vm.currentNum()==='3')?_c('li',[_vm._m(7)]):_vm._e(),(_vm.currentNum()==='4')?_c('li',[_vm._m(8)]):_vm._e(),(_vm.currentNum()==='4')?_c('li',[_vm._m(9)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/s1.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("创建实验")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/s2.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("实验详情")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/s3.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("实验数据")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/s4.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("场景方案")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/s.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("指标对比")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/s3.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("单模型应用")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/s4.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("应用中心")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/s.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("多模型编排")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/s3.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("补货单")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/s4.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("商品季节")])])
}]

export { render, staticRenderFns }