<template>
  <div>
    <ul class="ulStyle">
      <!--     单独场景-->
      <li v-if="currentNum()==='1'">
        <div class="item">
          <div class="top">
            <img src="./img/s.svg" alt="">
          </div>
          <div class="title">日常管理</div>
        </div>
      </li>
      <li v-if="currentNum()==='1'">
        <div class="item">
          <div class="top">
            <img src="./img/s2.svg" alt="">
          </div>
          <div class="title">系统相关场景</div>
        </div>
      </li>
      <li v-if="currentNum()==='1'">
        <div class="item">
          <div class="top">
            <img src="./img/s3.svg" alt="">
          </div>
          <div class="title">配置能力</div>
        </div>
      </li>
      <li v-if="currentNum()==='1'">
        <div class="item">
          <div class="top">
            <img src="./img/s4.svg" alt="">
          </div>
          <div class="title">多种策略</div>
        </div>
      </li>

      <!--2-->
      <li v-if="currentNum()==='2'">
        <div class="item">
          <div class="top">
            <img src="./img/s4.svg" alt="">
          </div>
          <div class="title">商品信息</div>
        </div>
      </li>
      <li v-if="currentNum()==='2'">
        <div class="item">
          <div class="top">
            <img src="./img/s3.svg" alt="">
          </div>
          <div class="title">销量预测</div>
        </div>
      </li>
      <li v-if="currentNum()==='2'">
        <div class="item">
          <div class="top">
            <img src="./img/s2.svg" alt="">
          </div>
          <div class="title">补货计划</div>
        </div>
      </li>

      <!--     3 -->
      <li v-if="currentNum()==='3'">
        <div class="item">
          <div class="top">
            <img src="./img/s4.svg" alt="">
          </div>
          <div class="title">标注平台</div>
        </div>
      </li>
      <li v-if="currentNum()==='3'">
        <div class="item">
          <div class="top">
            <img src="./img/s3.svg" alt="">
          </div>
          <div class="title">第三方数据标注</div>
        </div>
      </li>
      <!--      4-->
      <li v-if="currentNum()==='4'">
        <div class="item">
          <div class="top">
            <img src="./img/s4.svg" alt="">
          </div>
          <div class="title">历史销售数据</div>
        </div>
      </li>
      <li v-if="currentNum()==='4'">
        <div class="item">
          <div class="top">
            <img src="./img/s3.svg" alt="">
          </div>
          <div class="title">销售计划</div>
        </div>
      </li>
      <li v-if="currentNum()==='4'">
        <div class="item">
          <div class="top">
            <img src="./img/s2.svg" alt="">
          </div>
          <div class="title">库存结构</div>
        </div>
      </li>


      <!--      5-->
      <li v-if="currentNum()==='5'">
        <div class="item">
          <div class="top">
            <img src="./img/s4.svg" alt="">
          </div>
          <div class="title">结构化</div>
        </div>
      </li>
      <li v-if="currentNum()==='5'">
        <div class="item">
          <div class="top">
            <img src="./img/s3.svg" alt="">
          </div>
          <div class="title">任务管理</div>
        </div>
      </li>

    </ul>
  </div>
</template>
<script>
import {currentNum} from "@/utils/data";

export default {
  name: "sightMangement",
  methods: {
    currentNum() {
      return currentNum
    }
  }
}
</script>


<style>
.ulStyle {
  display: flex;

}

.item {
  margin: 15px;
  width: 120px;
  height: 100px;
  border: 1px solid #9e8e8e;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transition: all .3s ease-in-out;
  border-radius: 12px;
  background-color: white;

  &:hover {
    cursor: pointer;
    transition: all .3s ease-in-out;

    * {
      color: blue;
    }

    transform: translateY(-5px);

  }

}

.top {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 80%;
    height: 80%;
  }

  width: 100%;
  height: calc(100% - 30px);
}

.title {
  height: 30px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4c4948;
  transition: all .3s ease-in-out;
  font-weight: bold;

}
</style>