import demoTwo from "@/pages/demoTwo/index.vue";
import HelloWorld from "@/components/HelloWorld.vue";
import Home from "@/pages/home/index.vue";
import HomeIndex from "@/pages/home/homeIndex.vue";
import SuanShop from "@/pages/home/suanShop/index.vue";
import About from '@/pages/home/about/index.vue'
import Dmx from "@/pages/home/dmx/index.vue";
import Zs from "@/pages/home/zs/index.vue";
import Cxy from "@/pages/home/cxy/index.vue";
import SightMangement from "@/components/sightMangement/index.vue";
import SightMoreMangement from "@/components/sightMoreMangement/index.vue";
import GroupMangement from "@/components/groupMangement/index.vue";
import ExperimentPlatform from "@/components/experimentPlatform/index.vue";

export default [
    {
        path: '/home',
        name: 'home',
        component: Home,
        children: [
            {
                path: 'index',
                component: HomeIndex
            },
            {
                path: 'suanShop',
                component: SuanShop
            },
            {
                path: 'about',
                component: About
            },
            {
                path: 'dmx',
                component: Dmx
            },
            {
                path: 'zs',
                component: Zs
            },
            {
                path: 'cxy',
                component: Cxy
            },
        ]
    },
    {
        path: '/demoTwo',
        name: 'demoTwo',
        component: demoTwo
    },
    {
        path: '/hello',
        name: 'hello',
        component: HelloWorld,
        children: [
            {
                path: 'sightMangement',
                component: SightMangement
            },
            {
                path: 'sightMoreMangement',
                component: SightMoreMangement
            },
            {
                path: 'groupMangement',
                component: GroupMangement,
            },
            {
                path: 'experimentPlatform',
                component: ExperimentPlatform
            }
        ]

    },
    {
        path: '/',
        // redirect: '/hello/sightMangement',
        redirect: '/home/index',
    }
]