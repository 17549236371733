var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ul',{staticClass:"ulStyle"},[(_vm.currentNum()==='1')?_c('li',[_vm._m(0)]):_vm._e(),(_vm.currentNum()==='1')?_c('li',[_vm._m(1)]):_vm._e(),(_vm.currentNum()==='1')?_c('li',[_vm._m(2)]):_vm._e(),_vm._v(" "),(_vm.currentNum()==='1')?_c('li',[_vm._m(3)]):_vm._e(),(_vm.currentNum()==='3')?_c('li',[_vm._m(4)]):_vm._e(),(_vm.currentNum()==='3')?_c('li',[_vm._m(5)]):_vm._e(),(_vm.currentNum()==='4')?_c('li',[_vm._m(6)]):_vm._e(),(_vm.currentNum()==='4')?_c('li',[_vm._m(7)]):_vm._e(),(_vm.currentNum()==='4')?_c('li',[_vm._m(8)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/q2.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("画像属性")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/q3.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("物料集合")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/q4.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("场景服务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/q.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("物料分群")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/q3.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("调参方式")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/q4.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("保障系统")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/q3.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("销售预测")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/q4.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("库存周转率")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("./img/q2.svg"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("缺货风险")])])
}]

export { render, staticRenderFns }